import React, { ReactElement } from "react";
import Text from "@components/shared/text";
import Post from "./post";
import styles from "./style.module.scss";
import LinkButton from "@components/shared/form/linkButton";
import Button from "../form/button";
import { GQLPosts } from "@gql/types/gql";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";
import Pagination from "../Pagination/Pagination";

export default function BlogPosts({
    posts,
    title,
    type,
    onClick,
    isFetchingNextPage,
    hasNextPage,
    disabled,
    currentPage,
    url,
    total,
}: {
    posts: GQLPosts[];
    title?: string;
    type: "link" | "button" | "buttonWithPagination" | "pagination";
    onClick?: CallableFunction;
    isFetchingNextPage?: boolean;
    hasNextPage?: boolean;
    disabled?: boolean;
    currentPage?: number;
    url?: string;
    total?: number | undefined;
}): ReactElement {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    let content;
    if (type === "link") {
        content = (
            <div className="sm:w-[30%] w-[80%] mx-auto ">
                <LinkButton href="/blog" className="font-bold">
                    {translate(translations, "several_general_showMoreButton")}
                </LinkButton>
            </div>
        );
    }
    if (type === "button") {
        content = (
            <Button
                className="font-bold"
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
                disabled={disabled}
            >
                {translate(translations, "several_general_showMoreButton")}
            </Button>
        );
    }
    if (type === "buttonWithPagination") {
        content = !hasNextPage ? (
            <></>
        ) : (
            <div className="sm:w-[30%] w-[80%] mx-auto">
                <Button
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        }
                    }}
                    className="py-3 font-bold"
                    disabled={isFetchingNextPage || !hasNextPage}
                >
                    {!isFetchingNextPage
                        ? translate(
                              translations,
                              "several_general_readMoreButton",
                          )
                        : translate(translations, "several_general_loading")}
                </Button>
            </div>
        );
    }
    if (type === "pagination") {
        content = (
            <div className="w-[100%] sm:w-[80%] md:w-[50%] lg:w-[30%] mx-auto ">
                <Pagination
                    key={currentPage}
                    total={total as number}
                    offset={9}
                    currentPage={currentPage ?? 0}
                    url={url}
                />
            </div>
        );
    }

    return (
        <div className={`${styles.featuredBlogPostsWrapper} mx-auto`}>
            <Text type="title" className="mb-12 text-center">
                {title}
            </Text>

            <div className={`flex flex-wrap  ${styles.posts}`}>
                {posts?.map((post) => (
                    <Post data={post} key={post?.id} />
                ))}
            </div>
            <div className="text-center m-10 sm:m-20">
                <div className="">{content}</div>
            </div>
        </div>
    );
}
